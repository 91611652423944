<template>
    <div>
        <el-dialog center :visible.sync="dialogVisible" top="15%" width="600px">
            <div slot="title">
                <h4>{{ $t(title) }}</h4>
            </div>
            <div>
                <div class="center mt">{{ $t(content) }}</div>
                <el-input readonly class="margin-t" :value="link"></el-input>
            </div>
            <div slot="footer">
                <el-button v-clipboard:copy="link" icon="el-icon-document-copy" v-clipboard:success="firstCopySuccess"
                    v-clipboard:error="firstCopyError" type="primary" round style="width:200px">{{$t('一键复制')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "shareDialog",
    data() {
        return {
            dialogVisible: false,
            title: "分享",
            content: "",
            link: ""
        };
    },
    props: {
    },
    methods: {
        show(title, link, content) {
            this.title = title;
            this.link = link;
            if (content) {
                this.content = content;
            }
            this.dialogVisible = true;
        },

        firstCopySuccess() {
            this.dialogVisible = false;
            this.$message({
                message: this.$t('复制成功'),
                type: 'success'
            });
        },
        firstCopyError() {
            console.log("copy err");
        }
    },
};
</script>
<style scoped>
.drawer {
    padding: 30px 13%;
}

.drawer>>>.el-input__inner {
    border-radius: 20px;
    border-color: #eeeeee;
}
</style>
