<template>
    <div class="score">
        <el-dialog v-drag :visible.sync="dialogVisible" width="60%">
            <div class="mt bold" slot="title">{{ $t('AI评分') }}</div>
            <el-table :border="true" :data="tableData" style="width: 100%">
                <el-table-column prop="item1" :label="$t('单项')" width="300px">
                    <template slot-scope="scope">
                        <div>{{ $t(scope.row.item1) }}
                            <i slot="reference" @click="showDialog(scope.row.item1)"
                                class="el-icon-question lt color-green pointer"></i>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="item2" :label="$t('得分')">
                    <template slot-scope="scope">
                        <div class="st bold">{{ scope.row.item2 }}</div>
                        <el-progress v-if="info0.source.tag != 'ASQ'" status="success" :percentage="scope.row.item4"
                            :show-text="false"></el-progress>
                    </template>
                </el-table-column>
            </el-table>

            <div class="margin-t flex flex-v-center" style="position: relative;">
                <div class="mt bold" v-if="source.tag == 'ASQ'">{{ $t('评分') }}：<span class="color-primary">{{
                    info0.score
                        }}</span> /
                    1</div>
                <div class="mt bold" v-else-if="source.tag == 'SWT'">{{ $t('评分') }}：<span class="color-primary">{{
                    info0.score
                        }}</span>
                    /
                    7</div>
                <div class="mt bold" v-else-if="source.tag == 'WE'">{{ $t('评分') }}：<span class="color-primary">{{
                    info0.score
                        }}</span>
                    /
                    15</div>
                <div class="mt bold" v-else-if="source.tag == 'SST'">{{ $t('评分') }}：<span class="color-primary">{{
                    info0.score
                        }}</span>
                    /10
                </div>
                <div class="mt bold" v-else>{{ $t('评分') }}：<span class="color-primary">{{ format_number(info0.score *
                    0.9)
                        }}</span> / 90
                </div>
                <wave-player v-if="info0.content.indexOf('.wav') != -1" class="margin-x"
                    :src="$host + info0.content"></wave-player>
                <div class="st margin-l">{{ info0.result_time }}</div>

                <div style="position: absolute;right:20px;top:0;" class="mt bold color-primary"
                    v-if="['SWT', 'WE', 'SST'].indexOf(source.tag) != -1">{{ $t('字数') }}：{{
                        info0.content.split(/\s+/).length }}</div>
            </div>
            <div class="margin-lt"></div>
            <el-divider>
                <div v-if="info0.content.indexOf('.wav') != -1" class="flex flex-v-center">
                    <h3>{{ $t('AI语音识别') }}</h3>
                </div>
            </el-divider>

            <div v-if="info0.source.tag == 'SWT' || info0.source.tag == 'WE' || info0.source.tag == 'SST'"
                class="margin-t">
                <div class="st bold margin-bs">
                    {{ $t('AI评测结果') }}：
                </div>

                <div v-for="(row, i) in info.words.replace(/\./g, '. ').split('\n')" :key="i">

                    <div v-if="row">
                        <span :class="(word == ',' || word == '.' || word == '?') ? 'word0' : 'word0'" class="word"
                            v-for="(word, index) in row.replace(/\s+/g, ' ').split(' ')" :key="index">
                            <el-popover class="word" v-if="word.indexOf('^') != -1" placement="top-start" title=""
                                trigger="hover" :content="word.split('^')[1].replace(/】\w/g, '】')">
                                <span class="pointer" style="color:red;text-decoration: underline;word-break: keep-all;"
                                    slot="reference">{{
                                        word.split('^')[0] }}</span>
                            </el-popover>
                            <span v-else
                                :style="{ color: (word == row.replace(/\s+/g, ' ').split(' ')[index - 1] || word == row.replace(/\s+/g, ' ').split(' ')[index + 1]) ? 'red' : '' }"
                                style="word-break: keep-all;" v-word> {{ word }}</span>
                        </span>
                    </div>
                    <div v-else style="height: 10px;"></div>
                </div>
            </div>
            <div v-else-if="info0.source.tag == 'ASQ'" class="margin-t word">
                {{ info }}
            </div>
            <div v-else class="margin-t">
                <div v-if="['RA', 'RS'].indexOf(info0.source.tag) == -1" class="flex flex-h-end">
                    <div class="flex flex-v-center margin-x" v-for="(item, index) in items.slice(1)" :key="index">
                        <div class="dot" :class="'v' + (index + 1)"></div>
                        <div>{{ item }}</div>
                    </div>
                    <div class="flex flex-v-center margin-x">
                        <div>/ {{ $t('停顿') }}</div>
                    </div>
                </div>


                <div v-else class="flex flex-h-end">
                    <div class="flex flex-v-center margin-x" v-for="(item, index) in items" :key="index">
                        <div class="dot" :class="'v' + index"></div>
                        <div>{{ $t(item) }}</div>
                    </div>
                    <div class="flex flex-v-center margin-x">
                        <div>/ {{ $t('停顿') }}</div>
                    </div>
                </div>

                <div class="flex flex-wrap margin-t">
                    <el-popover width="400" @show="showDetail(item.word)" placement="top" trigger="click"
                        v-for="(item, index) in info.words" :key="index">
                        <div class="st1">{{ item.pronunciation > 80 ? $t("发音正确，请继续保持") : item.pronunciation > 60 ?
                            $t("发音有点问题，稍作改变会更完美")
                            : item.pronunciation > 0 ? $t("发音糟糕，请听正确发音并纠正") : $t("没有读这个单词") }}</div>
                        <div class="line margin-ts"></div>
                        <h3 class="margin-ts">{{ item.word }}</h3>
                        <div class="st"><span style="margin-right: 10px;">{{ $t("你的发音") }}:</span>[<span
                                v-for="(ph, index1) in item.phonemes"
                                :class="{ 'v0': ph.pronunciation < 60, 'v1': ph.pronunciation >= 60 && ph.pronunciation <= 80, 'v2': ph.pronunciation >= 80 }"
                                :key="index1">{{ ph.phoneme }}</span>]
                        </div>
                        <div v-loading="loading1">
                            <div class="st1 flex flex-v-center">[UK]<span style="margin:0 10px;">[{{
                                wordInfo.phonetic[0]
                                    }}]</span>
                                <el-image @click="play(1, item.word)" class="margin-x"
                                    style="width: 24px; height: 24px;cursor: pointer;"
                                    src="/web/image/playing.png"></el-image>
                            </div>
                            <div class="st1 flex flex-v-center">[US]<span style="margin:0 10px;">[{{
                                wordInfo.phonetic[1] }}]</span>
                                <el-image @click="play(2, item.word)" class="margin-x"
                                    style="width: 24px; height: 24px;cursor: pointer;"
                                    src="/web/image/playing.png"></el-image>
                            </div>
                            <div class="st1">
                                <div v-for="(row, index) in $t1(wordInfo, 'explanation').split('\n')" :key="index">
                                    {{ row }}
                                </div>
                            </div>
                        </div>
                        <span slot="reference" class="word" :class="getClass(item)">{{ item.word }}
                            <span style="color:#333"
                                v-if="index < info.words.length - 1 && (info.words[index + 1].start - info.words[index].end > 0.4)">//</span>
                            <span style="color:#333"
                                v-else-if="index < info.words.length - 1 && (info.words[index + 1].start - info.words[index].end > 0.1)">/</span>

                        </span>
                    </el-popover>
                </div>
                <div class="margin-t center st1">{{ $t('点击彩色单词可查看解析') }}</div>
            </div>
            <div slot="footer" class="center">
                <el-button type="primary" size="small" @click="dialogVisible = false">{{ $t('关闭') }}</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="$t(dialogType)" :visible.sync="centerDialogVisible" width="600" center>


            <div class="st1 break" v-if="dialogType == 'Content'">
                <div class="bold">{{ $t('评分规则') }}:</div>

                <div v-if="source.tag == 'SWT'">
                    <div><strong>2:</strong> Provides a good summary of the text. All relevant aspects mentioned</div>
                    <div><strong>1:</strong> Provides a fair summary of the text but misses one or two aspects</div>
                    <div><strong>0:</strong> Omits or misrepresents the main aspects of the text</div>
                    <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                    <div>{{ $t('一般来说，分段式文章，一个段落会有1-2个关键句，每段首句和尾句比较重要。本项如果是0分，电脑停止评判，本题直接0分。') }}</div>
                </div>

                <div v-else-if="source.tag == 'WE'">
                    <div><strong>3:</strong> Adequately deals with the prompt</div>
                    <div><strong>2:</strong> Deals with the prompt but does not deal with one minor aspect</div>
                    <div><strong>1:</strong> Deals with the prompt but omits a major aspect or more than one minor
                        aspect
                    </div>
                    <div><strong>0:</strong> Does not deal properly with the prompt</div>

                    <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                    <div>{{ $t('PTE写作对于内容要求不高，不用做到逻辑严谨，只要观点和支持合理即可') }}。</div>
                </div>

                <div v-else-if="source.tag == 'SST'">
                    <div><strong>2:</strong> Provides a good summary of the text. All relevant aspects are mentioned
                    </div>
                    <div><strong>1:</strong> Provides a fair summary of the text, but one or two aspects are missing
                    </div>
                    <div><strong>0:</strong> Omits or misrepresents the main aspects</div>
                    <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                    <div>{{ $t('SST基本重要信息有4-5点，pte是机考，所以看不懂行文逻辑，同学可以把重点放到中心词上') }}。</div>
                </div>
            </div>


            <div class="st1 break" v-if="dialogType == 'Form'">
                <div class="bold">{{ $t('评分规则') }}:</div>
                <div v-if="source.tag == 'SWT'">
                    <div><strong>1:</strong> Is written in one, single, complete sentence</div>
                    <div><strong>0:</strong> Not written in one, single, complete sentence or contains fewer than 5 or
                        more
                        than 75
                        words. Summary is
                        written in capital letters</div>
                    <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                    <div>{{ $t('字数5-75之间，且只有一个句号，即可得分；如果写成两句或者以上，电脑停止评判，本题直接0分') }}。</div>
                </div>

                <div v-else-if="source.tag == 'WE'">
                    <div><strong>2:</strong> Length is between 200 and 300 words</div>
                    <div><strong>1:</strong> Length is between 120 and 199 or between 301 and 380 words</div>
                    <div><strong>0:</strong> Length is less than 120 or more than 380 words. Essay is written in capital
                        letters,
                        contains no
                        punctuation or only consists of bullet points or very short sentences</div>

                    <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                    <div>{{ $t('保底分，只要字数写到200-300单词之间即可拿到满分2分') }}。</div>
                </div>


                <div v-else-if="source.tag == 'SST'">
                    <div><strong>2:</strong> Contains 50-70 words</div>
                    <div><strong>1:</strong> Contains 40-49 words or 71-100 words</div>
                    <div><strong>0:</strong> Contains less than 40 words or more than 100 words. Summary is written in
                        capital
                        letters, contains no
                        punctuation or consists only of bullet points or very short sentences</div>

                    <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                    <div>{{ $t('答案在50-70个单词即可，没有其他要求') }}。</div>
                </div>
            </div>


            <div class="st1 break" v-if="dialogType == 'Grammar'">
                <div class="bold">{{ $t('评分规则') }}:</div>
                <div v-if="source.tag == 'SWT'">
                    <div><strong>2:</strong> Has correct grammatical structure</div>
                    <div><strong>1:</strong> Contains grammatical errors but with no hindrance to communication</div>
                    <div><strong>0:</strong> Has defective grammatical structure which could hinder communication</div>

                    <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                    <div>{{ $t('占分相对较高，建议使用简单句，不犯语法错误即可满分') }}。</div>
                </div>

                <div v-else-if="source.tag == 'WE'">
                    <div><strong>2:</strong> Shows consistent grammatical control of complex language. Errors are rare
                        and
                        difficult
                        to spot</div>
                    <div><strong>1:</strong> Shows a relatively high degree of grammatical control. No mistakes which
                        would
                        lead to
                        misunderstandings
                    </div>
                    <div><strong>0:</strong> Contains mainly simple structures and/or several basic mistakes</div>

                    <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                    <div>{{ $t('语法需要需要复杂句，不犯语法错误') }}。</div>
                </div>

                <div v-else-if="source.tag == 'SST'">
                    <div><strong>2:</strong> Has correct grammatical structure</div>
                    <div><strong>1:</strong> Contains grammatical errors but with no hindrance to communication</div>
                    <div><strong>0:</strong> Has defective grammatical structure which could hinder communication</div>

                    <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                    <div>{{ $t('语法建议大家写简单句，不要尝试复杂句，因为要求是语法正确即可，而非长难句或者复杂句的表达形式') }}。</div>
                </div>
            </div>

            <div class="st1 break" v-if="dialogType == 'Spelling'">
                <div class="bold">{{ $t('评分规则') }}:</div>
                <div v-if="source.tag == 'WE'">
                    <div><strong>2:</strong> Correct spelling</div>
                    <div><strong>1:</strong> One spelling error</div>
                    <div><strong>0:</strong> More than one spelling error</div>
                    <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                    <div>{{ $t('拼写一个错误扣1分，注意不要英美混写') }}。</div>
                </div>
                <div v-else-if="source.tag == 'SST'">
                    <div><strong>2:</strong> Has correct grammatical structure</div>
                    <div><strong>1:</strong> Contains grammatical errors but with no hindrance to communication</div>
                    <div><strong>0:</strong> Has defective grammatical structure which could hinder communication</div>

                    <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                    <div>{{ $t('注意单词拼写，不能英美混写') }}。</div>
                </div>
            </div>


            <div class="st1 break" v-if="dialogType == 'Vocabulary range'">
                <div v-if="source.tag == 'WE'">
                    <div><strong>2:</strong> Good command of a broad lexical repertoire, idiomatic expressions and
                        colloquialisms
                    </div>
                    <div><strong>1:</strong> Shows a good range of vocabulary for matters connected to general academic
                        topics.
                        Lexical shortcomings
                        lead to circumlocution or some imprecision</div>
                    <div><strong>0:</strong> Contains mainly basic vocabulary insufficient to deal with the topic at the
                        required
                        level</div>
                    <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                    <div>{{ $t('词汇使用海澳专属10个观点句套所有题目，轻松搞定') }}。</div>
                </div>
            </div>


            <div class="st1 break" v-if="dialogType == 'General linguistic range'">
                <div v-if="source.tag == 'WE'">
                    <div><strong>2:</strong> Exhibits smooth mastery of a wide range of language to formulate thoughts
                        precisely,
                        give emphasis,
                        differentiate and eliminate ambiguity. No sign that the test taker is restricted in what they
                        want
                        to
                        communicate</div>
                    <div><strong>1:</strong> Sufficient range of language to provide clear descriptions, express
                        viewpoints
                        and
                        develop arguments
                    </div>
                    <div><strong>0:</strong> Contains mainly basic language and lacks precision</div>
                    <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                    <div>{{ $t('套用海澳专属模版结构，轻松搞定') }}。</div>
                </div>
            </div>


            <div class="st1 break" v-if="dialogType == 'Development, structure and coherence'">
                <div v-if="source.tag == 'WE'">
                    <div><strong>2:</strong> Shows good development and logical structure</div>
                    <div><strong>1:</strong> Is incidentally less well structured, and some elements or paragraphs are
                        poorly linked
                    </div>
                    <div><strong>0:</strong> Lacks coherence and mainly consists of lists or loose elements</div>
                    <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                    <div>{{ $t('要呈现4段式/5段式，主题段要有观点句 (中心句)和支持句') }}。</div>
                </div>
            </div>


            <div class="st1 break" v-if="dialogType == 'Vocabulary'">
                <div class="bold">{{ $t('评分规则') }}:</div>
                <div><strong>2:</strong> Has appropriate choice of words</div>
                <div><strong>1:</strong> Contains lexical errors but with no hindrance to communication</div>
                <div><strong>0:</strong> Has defective word choice which could hinder communication</div>

                <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                <div>{{ $t('要求是恰当的单词，允许使用原文词汇，不用替换单词') }}。</div>
            </div>





            <div class="st1 break" v-if="dialogType == '流利度'">
                <div class="bold">{{ $t('评分规则') }}:</div>
                <div>Each replacement, omission or insertion of a word counts as one error</div>

                <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                <div>{{ $t('流利度不仅仅是不卡顿，不重复和不犹豫，还包含了意群断句，节奏感，语速适中等其他要求') }}。</div>
            </div>

            <div class="st1 break" v-if="dialogType == '发音'">
                <div class="bold">{{ $t('评分规则') }}:</div>
                <div>
                    <strong>5:</strong> All vowels and consonants are produced in a manner that is easily understood by
                    regular
                    speakers
                    of the language. The speaker uses assimilation and deletions appropriate to continuous speech.
                    Stress is placed correctly in all words and sentence-level stress is fully appropriate
                </div>
                <div>
                    <strong>4:</strong> Vowels and consonants are pronounced clearly and unambiguously. A few minor
                    consonant, vowel
                    or
                    stress distortions do not affect intelligibility. All words are easily understandable. A few
                    consonants or consonant sequences may be distorted. Stress is placed correctly on all common
                    words,
                    and sentence level stress is reasonable
                </div>
                <div>
                    <strong>3:</strong> Most vowels and consonants are pronounced correctly. Some consistent errors
                    might
                    make a few
                    words
                    unclear. A few consonants in certain contexts may be regularly distorted, omitted or
                    mispronounced.
                    Stress-dependent vowel reduction may occur on a few words
                </div>
                <div>
                    <strong>2:</strong> Some consonants and vowels are consistently mispronounced in a non-native like
                    manner. At
                    least
                    2/3 of speech is intelligible, but listeners might need to adjust to the accent. Some consonants
                    are
                    regularly omitted, and consonant sequences may be simplified. Stress may be placed incorrectly on
                    some words or be unclear
                </div>
                <div>
                    <strong> 1:</strong> Many consonants and vowels are mispronounced, resulting in a strong intrusive
                    foreign
                    accent.
                    Listeners may have difficulty understanding about 1/3 of the words. Many consonants may be
                    distorted
                    or omitted. Consonant sequences may be non-English. Stress is placed in a non-English manner;
                    unstressed words may be reduced or omitted and a few syllables added or missed
                </div>

                <div>
                    <strong>0:</strong> Pronunciation seems completely characteristic of another language. Many
                    consonants
                    and
                    vowels
                    are
                    mispronounced, misordered or omitted. Listeners may find more than 1/2 of the speech
                    unintelligible.
                    Stressed and unstressed syllables are realized in a non-English manner. Several words may have the
                    wrong number of syllables
                </div>

                <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                <div>{{ $t('发音的评分标准比较综合，一共包含以下几点: 第一，音准问题，做到元音饱满辅音清晰；第二，有重弱读和单词重音；第三，要注意连读和省读现象；第四，不能蹦单词同时不能拖音') }}。
                </div>
            </div>

            <div class="st1 break" v-if="dialogType == '内容分'">
                <div class="bold">{{ $t('评分规则') }}:</div>
                <div>
                    <strong>5:</strong> Speech shows smooth rhythm and phrasing. There are no hesitations, repetitions,
                    false starts
                    or
                    non-native phonological simplifications
                </div>
                <div>
                    <strong>4:</strong> Speech has an acceptable rhythm with appropriate phrasing and word emphasis.
                    There
                    is no
                    more
                    than
                    one hesitation, one repetition or a false start. There are no significant non- native phonological
                    simplifications
                </div>
                <div>
                    <strong>3:</strong> Speech is at an acceptable speed but may be uneven. There may be more than one
                    hesitation,
                    but
                    most words are spoken in continuous phrases. There are few repetitions or false starts. There are
                    no
                    long pauses and speech does not sound staccato
                </div>
                <div>
                    <strong>2:</strong> Speech may be uneven or staccato. Speech (if >= 6 words) has at least one smooth
                    three-word
                    run,
                    and no more than two or three hesitations, repetitions or false starts. There may be one long
                    pause,
                    but not two or more
                </div>
                <div>
                    <strong>1:</strong> Speech has irregular phrasing or sentence rhythm. Poor phrasing, staccato or
                    syllabic
                    timing,
                    and/or multiple hesitations, repetitions, and/or false starts make spoken performance notably
                    uneven
                    or discontinuous. Long utterances may have one or two long pauses and inappropriate sentence-level
                    word emphasis
                </div>
                <div>
                    <strong>0:</strong> Speech is slow and labored with little discernable phrase grouping, multiple
                    hesitations,
                    pauses,
                    false starts, and/or major phonological simplifications. Most words are isolated, and there may be
                    more than one long pause
                </div>
                <div class="bold margin-t">{{ $t('答题建议') }}:</div>
                <div>{{ $t('替换，漏读或者添加单词都是一个错误。考试遇到大词难词，可以直接跳过。不建议使用something替代难词，因为这样扣分比直接跳过要高') }}。</div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" @click="centerDialogVisible = false">{{ $t('关闭') }}</el-button>
            </span>
        </el-dialog>
        <audio ref="audio"></audio>
    </div>
</template>

<script>
import WavePlayer from '../components/WavePlayer.vue';
import drag from "./drag";

export default {
    name: "scoreDialog",
    components: {
        WavePlayer
    },
    directives: {
        drag
    },
    props: {
        mock: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dialogType: "流利度",
            dialogVisible: false,
            info: {},
            info0: { source: {}, content: "" },
            tableData: [],
            items: ['漏读', '差', '良', '优'],
            wordInfo: { phonetic: [], explanation: "" },
            loading1: false,
            source: {},
            centerDialogVisible: false,
        };
    },
    methods: {
        format_number(val) {
            return parseFloat(val).toFixed(0);
        },
        play(type, word) {
            this.src = this.$host + "/api/word/audio/" + word + "/" + type
            this.$refs.audio.src = this.src;
            this.$refs.audio.play();
        },

        getClass(item) {
            if (item.pronunciation == 0) {
                if (['RA', 'RS'].indexOf(this.info0.source.tag) == -1) {
                    return "v1";
                } else {
                    return "v0";
                }
            } else if (item.pronunciation < 60) {
                return "v1";
            } else if (item.pronunciation < 85) {
                return "v2";
            } else if (item.pronunciation <= 100) {
                return "v3";
            }
        },

        showDialog(title) {
            this.dialogType = title;
            this.centerDialogVisible = true;
        },

        showDetail(word) {
            let info = this.$store.getters.getWord(word);
            if (info) {
                this.wordInfo = info;
            } else {
                this.loading1 = true;
                this.$http.post("/api/word/info", { word: word }).then((info) => {
                    this.wordInfo = info;
                    this.$store.commit("addWord", info);
                    this.loading1 = false;
                });
            }
        },


        show(id) {
            this.tableData = [];
            if (this.mock) {
                this.$http.post("/api/mock/record/item/info", { id: id }).then((info) => {
                    this.showInfo(info);
                })
            } else {
                this.$http.post("/api/record/info", { id: id }).then((info) => {
                    this.showInfo(info);
                })
            }

        },


        showInfo(info) {
            this.tableData = [];
            this.dialogVisible = true;
            this.info0 = info;

            this.info = info.result;

            this.source = info.source;
            this.tableData = [];
            if (this.source.tag == "SWT") {
                this.tableData.push({
                    item1: "Content",
                    item2: this.info.content + "/2",
                    item4: parseInt(this.info.content / 2 * 100)
                })
                this.tableData.push({
                    item1: "Form",
                    item2: this.info.form + "/1",
                    item4: parseInt(this.info.form / 1 * 100)
                })
                this.tableData.push({
                    item1: "Grammar",
                    item2: this.info.grammar + "/2",
                    item4: parseInt(this.info.grammar / 2 * 100)
                })

                this.tableData.push({
                    item1: "Vocabulary",
                    item2: this.info.vocabulary + "/2",
                    item4: parseInt(this.info.vocabulary / 2 * 100)
                })
            } else if (this.source.tag == "WE") {
                this.tableData.push({
                    item1: "Content",
                    item2: this.info.content + "/3",
                    item4: parseInt(this.info.content / 3 * 100)
                })
                this.tableData.push({
                    item1: "Form",
                    item2: this.info.form + "/2",
                    item4: parseInt(this.info.form / 2 * 100)
                })
                this.tableData.push({
                    item1: "Grammar",
                    item2: this.info.grammar + "/2",
                    item4: parseInt(this.info.grammar / 2 * 100)
                })




                this.tableData.push({
                    item1: "Vocabulary range",
                    item2: this.info.vocabularyRange + "/2",
                    item4: parseInt(this.info.vocabularyRange / 2 * 100)
                })

                this.tableData.push({
                    item1: "General linguistic range",
                    item2: this.info.linguisticRange + "/2",
                    item4: parseInt(this.info.linguisticRange / 2 * 100)
                })


                this.tableData.push({
                    item1: "Development, structure and coherence",
                    item2: this.info.structure + "/2",
                    item4: parseInt(this.info.structure / 2 * 100)
                })

                this.tableData.push({
                    item1: "Spelling",
                    item2: this.info.spelling + "/2",
                    item4: parseInt(this.info.spelling / 2 * 100)
                })
            } else if (this.source.tag == "SST") {
                this.tableData.push({
                    item1: "Content",
                    item2: this.info.content + "/2",
                    item4: parseInt(this.info.content / 2 * 100)
                })
                this.tableData.push({
                    item1: "Form",
                    item2: this.info.form + "/2",
                    item4: parseInt(this.info.form / 2 * 100)
                })
                this.tableData.push({
                    item1: "Grammar",
                    item2: this.info.grammar + "/2",
                    item4: parseInt(this.info.grammar / 2 * 100)
                })

                this.tableData.push({
                    item1: "Vocabulary",
                    item2: this.info.vocabulary + "/2",
                    item4: parseInt(this.info.vocabulary / 2 * 100)
                })

                this.tableData.push({
                    item1: "Spelling",
                    item2: this.info.spelling + "/2",
                    item4: parseInt(this.info.spelling / 2 * 100)
                })
            } else if (this.source.tag == "ASQ") {
                this.tableData.push({
                    item1: "内容分",
                    item2: info.score + "/1",
                    item3: this.$t("流利度不仅仅是不卡顿，不重复和不犹豫，还包含了意群断句，节奏感，语速适中等其他要求。"),
                })
            } else {
                this.tableData.push({
                    item1: "内容分",
                    item2: (parseInt(info.result.integrity * 0.9) < 10 ? 10 : parseInt(info.result.integrity * 0.9)) + "/90",
                    item3: this.$t("流利度不仅仅是不卡顿，不重复和不犹豫，还包含了意群断句，节奏感，语速适中等其他要求。"),
                    item4: 0
                })

                this.tableData.push({
                    item1: "发音",
                    item2: (parseInt(info.result.pronunciation * 0.9) < 10 ? 10 : parseInt(info.result.pronunciation * 0.9)) + "/90",
                    item3: this.$t("发音的评分标准比较综合，一共包含以下几点: 第一，音准问题，做到元音饱满辅音清晰；第二，有重弱读和单词重音；第三，要注意连读和省读现象，第四，不能蹦单词同时不能拖音。"),
                    item4: 0
                })

                this.tableData.push({
                    item1: "流利度",
                    item2: (parseInt(info.result.fluency * 0.9) < 10 ? 10 : parseInt(info.result.fluency * 0.9)) + "/90",
                    item3: this.$t("漏读或者添加单词都是一个错误。考试遇到大词难词，可以直接跳过。不建议使用something替代难词，因为这样扣分比直接跳过要高。"),
                    item4: 0
                })

                console.log(this.tableData)
                setTimeout(() => {
                    this.tableData[0].item4 = info.result.integrity * 0.9 <= 10 ? (10 / 90 * 100) : parseInt(info.result.integrity)

                    this.tableData[1].item4 = info.result.pronunciation * 0.9 <= 10 ? (10 / 90 * 100) : parseInt(info.result.pronunciation)

                    this.tableData[2].item4 = info.result.fluency * 0.9 <= 10 ? (10 / 90 * 100) : parseInt(info.result.fluency);

                }, 800);
            }


            // if (info && info.result_time && info.source.sort == "Speaking") {
            //     if (this.source.tag == "ASQ") {
            //         this.tableData.push({
            //             item1: "内容分",
            //             item2: info.score + "/1",
            //             item3: "流利度不仅仅是不卡顿，不重复和不犹豫，还包含了意群断句，节奏感，语速适中等其他要求。",
            //         })
            //     } else {


            //         this.tableData.push({
            //             item1: "流利度",
            //             item2: parseInt(info.result.fluency * 0.9) < 10 ? 10 : parseInt(info.result.fluency * 0.9) + "/90",
            //             item3: "漏读或者添加单词都是一个错误。考试遇到大词难词，可以直接跳过。不建议使用something替代难词，因为这样扣分比直接跳过要高。",
            //             item4: 0
            //         })
            //         this.tableData.push({
            //             item1: "发音",
            //             item2: parseInt(info.result.pronunciation * 0.9) < 10 ? 10 : parseInt(info.result.pronunciation * 0.9) + "/90",
            //             item3: "发音的评分标准比较综合，一共包含以下几点: 第一，音准问题，做到元音饱满辅音清晰；第二，有重弱读和单词重音；第三，要注意连读和省读现象，第四，不能蹦单词同时不能拖音。",
            //             item4: 0
            //         })
            //         this.tableData.push({
            //             item1: "内容分",
            //             item2: parseInt(info.result.integrity * 0.9) < 10 ? 10 : parseInt(info.result.integrity * 0.9) + "/90",
            //             item3: "流利度不仅仅是不卡顿，不重复和不犹豫，还包含了意群断句，节奏感，语速适中等其他要求。",
            //             item4: 0
            //         })

            //         setTimeout(() => {
            //             this.tableData[0].item4 = parseInt((parseInt(info.result.fluency * 0.9) < 10 ? 10 : parseInt(info.result.fluency * 0.9)) / 90 * 100);
            //             this.tableData[1].item4 = parseInt((parseInt(info.result.pronunciation * 0.9) < 10 ? 10 : parseInt(info.result.pronunciation * 0.9)) / 90 * 100)
            //             this.tableData[2].item4 = parseInt((parseInt(info.result.integrity * 0.9) < 10 ? 10 : parseInt(info.result.integrity * 0.9)) / 90 * 100)
            //         }, 800);
            //     }
            // }
        },
    },
};
</script>
<style scoped>
.word {
    margin: 2.5px;
    font-size: 18px;
}

.word.word0 {
    margin: 0;
    padding: 0;
}

.word.word-dot {
    margin: 0;
    padding: 0;
    margin-left: -10px;
    cursor: auto;
}

.word.word-dot:hover {
    background-color: transparent;
}


.word.v0 {
    color: #909399;
}

.word.v1 {
    color: #F56C6C;
}

.word.v2 {
    color: #E6A23C;
}

.word.v3 {
    color: #67C23A;
}


.v0 {
    color: red;
}

.v1 {
    color: yellow;
}

.dot {
    margin-top: 5px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
}

.dot.v0 {
    background-color: #909399;
}

.dot.v1 {
    background-color: #F56C6C;
}

.dot.v2 {
    background-color: #E6A23C;
}

.score>>>.el-progress.is-success .el-progress-bar__inner {
    background-color: #e6a13c;
}

.dot.v3 {
    background-color: #67C23A;
}
</style>