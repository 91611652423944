<template>
    <transition v-if="popup" name="el-fade-in-linear">
        <VueDragResize tabindex="0" contenteditable="true" ref="drag" @activated="onActivated"
            @deactivated="onDeactivated" v-if="isShow" class="fix-center" :class="source.sort" style="position:fixed"
            :z="2000" :minw="400" :minh="300" :w="width" :h="height" :isActive="true" v-on:resizing="resize">
            <el-card class="drag-resize" style="cursor:move;border-radius: 10px;"
                :style="{ height: height + 'px', width: width + 'px' }">
                <h3 slot="header" class="ellipsis">{{ '#' + source.no + ' ' + $t1(source,'title') }}</h3>
                <div class="st" style="margin-bottom: 5px;" v-if="getText()">
                    {{ $t(getText()) }} <span class="">{{ source.tag == 'RO' ? ('(' + $t('以下为正确顺序') + ')') : ''
                        }}</span>
                </div>
                <div class="mt" :style="{ height: (height - marginTop) + 'px' }"
                    style="overflow-y: scroll;overflow-x: hidden;background: #f0f0f0;padding:0 10px;">

                    <div v-for="(line, index) in words_ens" :key="index">
                        <div class="margin-t break flex flex-wrap" v-if="line.trim()">
                            <template class="flex flex-wrap"
                                v-for="(items, index1) in line.trim().replaceAll('·', '.').split('^')">
                                <template v-for="(word, index2) in items.split(' ')">
                                    <span
                                        :class="[index1 % 2 == 1 ? 'br1' : '', word.trim().replace(/\W/g, '').length == 0 ? 'end' : 'word1']"
                                        @click.prevent="$bus.$emit('word', word)" v-if="word" :key="index2">{{ word
                                        }}</span>
                                </template>
                            </template>
                        </div>
                        <el-tag class="tip st1 margin-ts"
                            v-if="$store.state.lang != 'en' && line.trim() && words_zhs[index] && words_zhs[index].trim()" type="info">{{
                            $t(words_zhs[index])
                            }}</el-tag>
                    </div>
                </div>

                <div class="right margin-t">
                    <!-- <span class="st">提示：此显示框可以拖动位置，可以缩放哦</span> -->
                    <el-button round type="primary" @click="isShow = false">{{$t('关闭')}}</el-button>
                </div>
            </el-card>
        </VueDragResize>
    </transition>

    <div v-else>
        <div v-for="(line, index) in words_ens" :key="index">
            <div class="break flex flex-wrap" v-if="line.trim()">
                <template class="flex flex-wrap" v-for="(items, index1) in line.trim().replaceAll('·', '.').split('^')">
                    <template v-for="(word, index2) in items.split(' ')">
                        <span
                            :class="[index1 % 2 == 1 ? 'br1' : '', word.trim().replace(/\W/g, '').length == 0 ? 'end' : 'word-mock']"
                            v-if="word" :key="index1 + '_' + index2">{{ word
                            }}</span>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import VueDragResize from 'vue-drag-resize';
export default {
    components: {
        VueDragResize
    },
    name: "wordsResizePanel",
    data() {
        return {
            width: 900,
            height: 600,
            isShow: false,
            left: 0,
            marginTop: 170,
            words_ens: [],
            words_zhs: [],
        };
    },
    props: {

        source: {
            type: Object,
            default: () => {
                return {};
            },
        },

        type: {
            type: String,
            default: "pte",
        },


        popup: {
            type: Boolean,
            default: true
        }
    },

    created() {
        this.format();
    },

    watch: {
        source() {
            this.format();
        }
    },

    methods: {
        format() {
            if (!(this.source && this.source.words_en && this.source.words_zh)) return;
            let words_ens = [];
            let words_zhs = [];

            if (this.type == "ccl") {
                this.source.words_en.split("\n").forEach(item => {
                    words_ens.push(item)
                });
                this.source.words_zh.split("\n").forEach(item => {
                    words_zhs.push(item)
                });
            } else {
                this.source.words_en.split("\n").forEach(item => {
                    if (this.source.tag == "RO") {
                        words_ens.push(item)
                    } else {
                        item = item.replace(/[.?!]"{0,1}/g, (val) => { return val + "\n" });
                        // item = item.replace(/\n{2,}/g,"/n");
                        item.split("\n").forEach((item1) => {
                            words_ens.push(item1)
                        })
                    }
                });

                this.source.words_zh.split("\n").forEach(item => {
                    if (this.source.tag == "RO") {
                        words_zhs.push(item)
                    } else {
                        item = item.replace(/[。？！]”{0,1}/g, (val) => { return val + "\n" });
                        item.split("\n").forEach((item1) => {
                            words_zhs.push(item1)
                        })
                    }

                });

            }

            this.words_ens = words_ens;
            this.words_zhs = words_zhs;
        },


        onActivated() {
        },

        onDeactivated() {
            if (!this.$store.state.wordDialogShow && this.source.tag != 'RL' && this.source.tag != 'SST') {
                this.isShow = false;
            }
        },

        show() {
            this.height = 600;
            this.width = 900;
            this.isShow = true;
        },

        hide() {
            this.isShow = false;
        },

        resize(res) {
            this.width = res.width;
            this.height = res.height;
        },

        getText() {
            let text = "";
            if (this.source.tag == 'RL' || this.source.tag == 'SST') {
                text = "非原文：非考试音频文本，仅供练习";
                if (this.source.is_original_text == 1) {
                    text = "原文：考试音频文本";
                } else if (this.source.is_original_text == 2) {
                    text = "仅大意：非完整回忆，基于考生回忆";
                }
            } else if (this.source.tag == 'SWT' || this.source.tag == 'RO') {
                text = "非原文：仅供练习";
                if (this.source.is_original_text == 1) {
                    text = "原文：考场原文";
                } else if (this.source.is_original_text == 2) {
                    text = "仅大意：非完整回忆，仅供参考";
                }
            }
            if (text) {
                this.marginTop = 170;
            } else {
                this.marginTop = 150;
            }
            return text;
        }
    },
};
</script>

<style scoped>
.Speaking>>>.el-card__header {
    color: #fff;
    background: #de7d89;
}

.Speaking>>>.el-tag--primary {
    background: #de7d89 !important;
}



.Writing>>>.el-card__header {
    color: #fff;
    background: #796f85;
}


.Writing>>>.el-tag--primary {
    background: #796f85 !important;
}




.Reading>>>.el-tag--primary {
    background: #d5795d !important;
}


.Reading>>>.el-card__header {
    background: #d5795d;
    color: #fff;
}

.Listening>>>.el-card__header {
    background: #86a6c5;
    color: #fff;
}

.Listening>>>.el-tag--primary {
    background: #86a6c5 !important;
}

.br1 {
    color: red;
    /* font-weight: bold; */
}

.fix-center {
    margin-top: calc(50vh - 300px);
    margin-left: calc(50vw - 450px);
}

.drag-resize>>>.el-card__body {
    padding-top: 5px;
}

.end {
    margin-left: -2px;
    line-height: 20px;
}
</style>